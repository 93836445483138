





































import Vue from 'vue';
import { mapState } from 'vuex';
import { IOrder } from '@/services/Repositories/OrdersRepository/types';

export default Vue.extend({
  name: 'Order',
  data() {
    return {
      toDelete: false,
    };
  },
  props: {
    order: {
      type: Object as () => IOrder,
      required: true,
    },
  },
  computed: {
    ...mapState('orders', ['deleteMode']),
    orderCompleted(): boolean {
      return (this as any).order.shipStatus === 'SHIPPED';
    },
    orderCompletedOpacity(): string {
      return (this as any).orderCompleted ? '0.66' : '1';
    },
    fullName(): string {
      return `${(this as any).order.custName} ${(this as any).order.custSurname}`;
    },
    customerNumber(): string {
      const { custNr } = (this as any).order;
      const customerNumber = this.$i18n.t('order.custNr', { customerNumber: custNr });
      return customerNumber.toString();
    },
    orderNumber(): string {
      const { orderNr: orderNumber } = (this as any).order;
      const orderNr = this.$i18n.t('order.orderNumber', { orderNumber });
      return orderNr.toString();
    },
  },
  methods: {
    redirectToDetails() {
      (this as any).$router.push({ name: 'orderDetails', params: { orderId: (this as any).order.id.toString() } });
    },
    selectOrder() {
      (this as any).toDelete = !(this as any).toDelete;
      this.$emit('selectedOrders', (this as any).order);
    },
  },
});
